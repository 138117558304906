import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CREW_SEARCH_STATUSES} from 'app/static/crew-search-status';
import {USER_GROUPS} from 'app/static/user-group-type';
import {Observable, of} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {CHARTER_PERIOD_STATUS_BADGE, CHARTER_PERIOD_TYPE, CHARTER_TYPE_BADGE} from '@features/charter/shared/static';
import {APA_OPERATIONS} from '@features/charter/shared/static/apa-report/apa-report';
import {buildURLParams} from '@helpers/build-url-params';
import {httpParamsFromObject} from '@helpers/http-params';
import {BoatSearchSupplierPayload} from '@models/boat-supplier';
import {CharterPeriodStatusItem, CharterTypeItem, PeriodTypeItem} from '@models/charters';
import {ContractPeriod} from '@models/crew';
import {CrewSearchStatusItem} from '@models/crew/crew.model';
import {CrewSearchSortType} from '@models/crew-search';
import {
  ActivityTypeDto,
  ApaOperation,
  BoatAreaDto,
  BoatFleetUserPermissionsDto,
  BoatTypeDto,
  BoatTypeSectionDto,
  BrandDto,
  CharterAdditionalPaymentMethodDto,
  CharterContractStatusDto,
  CharterKindDto,
  CityDto,
  CompanyTypeDto,
  ContractTypeDto,
  CountryDto,
  CurrencyDirectoriesDto,
  CvAvailableStatusDto,
  CvDocumentDefaultDto,
  CvDocumentDto,
  CvVisaGroupDto,
  DirectoriesDto,
  GenderDto,
  GeneralSettingsDto,
  GuestKindTypeDto,
  JobStartDateDto,
  LanguageDto,
  LanguageLevelDto,
  MaritalStatusDto,
  OutstandingPaymentOrderStatusDto,
  OutstandingPaymentStatusColorItemDto,
  PermissionsGroupDto,
  PersonalCharacteristicDto,
  ReleaseInfoDto,
  RoleDto,
  RolePositionDto,
  RolePositionTypeDto,
  SearchRegionItemDto,
  SearchSortItemDto,
  SupplierBrandDto,
  SupplierCatalogRoleDto,
  SupplierGeneralTag,
  SupplierPaymentTermDto,
  SupplierRoleDto,
  TableSettingsDto,
  TimezoneItemDto,
  UniformSizeDto,
  UserRoleType,
  VatTypeItem,
  WorkPreferencesCurrencyDto,
} from '@models/directories';
import {ExpenseStatusItem, ExpenseTypeItem, SubCategoryDto} from '@models/expense';
import {ConditionalStatus, LabeledItem} from '@models/general';
import {InvoiceFilterList} from '@models/invoice';
import {UnpublishReasonI} from '@models/job-offers';
import {JobSearchSortType} from '@models/job-search';
import {
  ExtendedLocationItemDto,
  GeolocationDtoI,
  LocationDtoI,
  LocationLatLngDto,
  LocationSearchMode,
  LocationTypeAddress,
} from '@models/location';
import {ResponseDto} from '@models/response';
import {SupplierSearchSortType} from '@models/suppliers-search';
import {CHARTER_ADDITIONAL_PAYMENT_STATUSES, CHARTER_VAT_MODES} from '@static/charter-payments';
import {budgetPeriods, contractCrewPeriods} from '@static/contract';
import {EXPENSE_STATUSES_LIST, EXPENSE_TYPE_LIST} from '@static/expense';
import {INVOICE_FILTER_LIST} from '@static/invoice-filter-list';
import {JOB_START_DATE_LIST} from '@static/job-start-date';
import {CREW_SEARCH_SORT_LIST, JOB_SEARCH_SORT_LIST, SUPPLIERS_SEARCH_SORT_LIST} from '@static/search';
import {UNPUBLISH_OFFER_ACTIONS} from '@static/unpublish-offer-actions';
import {VAT_INCLUDED_LIST} from '@static/vat-included';

@Injectable({
  providedIn: 'root',
})
export class DirectoriesService {

  readonly roles$: Observable<RoleDto[]> = this.getDirectory('role').pipe(
    map(directories => directories.role),
    shareReplay(1),
  );

  readonly countries$: Observable<CountryDto[]> = this.getDirectory('country').pipe(
    map(directories => directories.country),
    shareReplay(1),
  );

  readonly boatType$: Observable<BoatTypeDto[]> = this.getDirectory('boatType').pipe(
    map(directories => directories.boatType),
    shareReplay(1),
  );

  readonly activityType$: Observable<ActivityTypeDto[]> = this.getDirectory('boatActivityType').pipe(
    map(directories => directories.boatActivityType),
    shareReplay(1),
  );

  readonly brand$: Observable<BrandDto[]> = this.getDirectory('brand').pipe(
    map(directories => directories.brand),
    shareReplay(1),
  );

  readonly paymentMethods$ = this.getDirectory('paymentMethod').pipe(
    map(directories => directories.paymentMethod),
    shareReplay(1),
  );

  readonly apaPaymentMethods$ = this.getDirectory('apaPaymentMethod').pipe(
    map(directories => directories.apaPaymentMethod),
    shareReplay(1),
  );

  readonly cardsPaymentMethods$ = this.getDirectory('cardStatementPayment').pipe(
    map(directories => directories.cardStatementPayment),
    shareReplay(1),
  );

  readonly pettyCashPaymentMethods$ = this.getDirectory('boatPettyCashBalancePaymentMethod').pipe(
    map(directories => directories.boatPettyCashBalancePaymentMethod),
    shareReplay(1),
  );

  readonly fleetPermissions$: Observable<BoatFleetUserPermissionsDto[]> =
    this.getDirectory('fleetPermission').pipe(
      map(directories => directories.fleetPermission),
      shareReplay(1),
    );

  readonly transactionTypes$: Observable<ApaOperation[]> = of(APA_OPERATIONS).pipe(
    shareReplay(1),
  );

  readonly expenseTypes$: Observable<ExpenseTypeItem[]> = of(EXPENSE_TYPE_LIST).pipe(
    shareReplay(1),
  );

  readonly vatIncludedTypes$: Observable<VatTypeItem[]> = of(VAT_INCLUDED_LIST).pipe(
    shareReplay(1),
  );

  readonly expenseStatus$: Observable<ExpenseStatusItem[]> = of(EXPENSE_STATUSES_LIST).pipe(
    shareReplay(1),
  );

  readonly userGroups$: Observable<LabeledItem[]> = of(USER_GROUPS).pipe(
    shareReplay(1),
  );

  readonly invoiceFilter$: Observable<InvoiceFilterList> = of(INVOICE_FILTER_LIST).pipe(
    shareReplay(1),
  );

  readonly contractTypes$: Observable<ContractTypeDto[]> = this.getDirectory('contractType').pipe(
    map(directories => directories.contractType),
    shareReplay(1),
  );

  readonly positionTypes$: Observable<RolePositionTypeDto[]> = this.getDirectory('rolePositionType').pipe(
    map(directories => directories.rolePositionType),
    shareReplay(1),
  );

  readonly allPositions$: Observable<RolePositionDto[]> = this.positionTypes$.pipe(
    map(types => types
      .reduce((acc: RolePositionDto[], value) => [...acc, ...value.positions], [])
      .sort((a, b) => a.name < b.name ? -1 : 1),
    ),
  );

  readonly gender$: Observable<GenderDto[]> = this.getDirectory('gender').pipe(
    map(directories => directories.gender),
    shareReplay(1),
  );

  readonly maritalStatus$: Observable<MaritalStatusDto[]> = this.getDirectory('maritalStatus').pipe(
    map(directories => directories.maritalStatus),
    shareReplay(1),
  );

  readonly uniformSize$: Observable<UniformSizeDto[]> = this.getDirectory('uniformSize').pipe(
    map(directories => directories.uniformSize),
    shareReplay(1),
  );

  readonly boatClassNames$: Observable<any> = this.getDirectory('className').pipe(
    map(directories => directories.className),
    shareReplay(1),
  );

  readonly language$: Observable<LanguageDto[]> = this.getDirectory('language').pipe(
    map(directories => directories.language),
    shareReplay(1),
  );

  readonly languageLevel$: Observable<LanguageLevelDto[]> = this.getDirectory('languageLevel').pipe(
    map(directories => directories.languageLevel),
    shareReplay(1),
  );

  readonly boatArea$: Observable<BoatAreaDto[]> = this.getDirectory('boatArea').pipe(
    map(directories => directories.boatArea),
    shareReplay(1),
  );

  readonly workPreferencesCurrency$: Observable<WorkPreferencesCurrencyDto[]> =
    this.getDirectory('workPreferencesCurrency').pipe(
      map(directories => directories.workPreferencesCurrency),
      shareReplay(1),
    );

  readonly personalCharactertic$: Observable<PersonalCharacteristicDto[]> =
    this.getDirectory('personalCharacteristic').pipe(
      map(directories => directories.personalCharacteristic),
      shareReplay(1),
    );

  readonly cvAvailableStatus$: Observable<CvAvailableStatusDto[]> = this.getDirectory('cvAvailable').pipe(
    map(directories => directories.cvAvailable),
    shareReplay(1),
  );

  readonly cvDocumentDefault$: Observable<CvDocumentDefaultDto[]> = this.getDirectory('cvDocumentDefault').pipe(
    map(directories => directories.cvDocumentDefault),
    shareReplay(1),
  );

  readonly cvDocumentList$: Observable<CvDocumentDto[]> = this.getDirectory('cvDocumentList').pipe(
    map(directories => directories.cvDocumentList),
    shareReplay(1),
  );

  readonly cvVisaGroup$: Observable<CvVisaGroupDto[]> = this.getDirectory('cvVisaGroup').pipe(
    map(directories => directories.cvVisaGroup),
    shareReplay(1),
  );

  readonly permissionGroups$: Observable<PermissionsGroupDto[]> = this.getDirectory('permissionGroups').pipe(
    map(directories => directories.permissionGroups),
    shareReplay(1),
  );

  readonly currency$: Observable<CurrencyDirectoriesDto[]> = this.getDirectory('currency').pipe(
    map(directories => directories.currency),
    shareReplay(1),
  );

  readonly crewSearchStatus$: Observable<CrewSearchStatusItem[]> = of(CREW_SEARCH_STATUSES).pipe(
    shareReplay(1),
  );

  readonly filterCrewSearchStatus$: Observable<CrewSearchStatusItem[]> = of(CREW_SEARCH_STATUSES).pipe(
    map(statuses => statuses.filter(st => st.showInSearchFilter)),
    shareReplay(1),
  );

  readonly contractPeriod$: Observable<ContractPeriod[]> = of(contractCrewPeriods).pipe(
    shareReplay(1),
  );

  readonly crewSearchSort$: Observable<SearchSortItemDto<CrewSearchSortType>[]> = of(CREW_SEARCH_SORT_LIST).pipe(
    shareReplay(1),
  );

  readonly jobSearchSort$: Observable<SearchSortItemDto<JobSearchSortType>[]> = of(JOB_SEARCH_SORT_LIST).pipe(
    shareReplay(1),
  );

  readonly suppliersSearchSort$: Observable<SearchSortItemDto<SupplierSearchSortType>[]> =
    of(SUPPLIERS_SEARCH_SORT_LIST).pipe(
      shareReplay(1),
    );

  readonly generalSettings$: Observable<GeneralSettingsDto> = this.getDirectory('settingsGeneral').pipe(
    map(directories => directories.settingsGeneral),
    shareReplay(1),
  );

  readonly reasonOfUnpublishing$: Observable<UnpublishReasonI[]> = this.getDirectory('reasonOfUnpublishing').pipe(
    map(directories => directories.reasonOfUnpublishing),
    shareReplay(1),
  );

  readonly unpublishOfferActions$: Observable<LabeledItem[]> = of(UNPUBLISH_OFFER_ACTIONS).pipe(
    shareReplay(1),
  );

  readonly jobStartDate$: Observable<JobStartDateDto[]> = of(JOB_START_DATE_LIST).pipe(
    shareReplay(1),
  );

  readonly guestKindType$: Observable<GuestKindTypeDto[]> = this.getDirectory('guestKindType').pipe(
    map(directories => directories.guestKindType),
    shareReplay(1),
  );

  readonly outstandingPaymentOrderStatus$: Observable<OutstandingPaymentOrderStatusDto[]> =
    this.getDirectory('workOrderStatusGroup').pipe(
      map(directories => directories.outstandingPaymentWorkOrderStatus),
      shareReplay(1),
    );

  readonly outstandingPaymentStatusColor$: Observable<OutstandingPaymentStatusColorItemDto[]> =
    this.getDirectory('outstandingPaymentStatusColor').pipe(
      map(directories => directories.outstandingPaymentStatusColor),
      shareReplay(1),
    );

  readonly budgetPeriods: ContractPeriod[] = budgetPeriods;

  get years$(): Observable<number[]> {
    const currentYear = new Date().getFullYear();
    const startYear = 1970;
    const length = currentYear - startYear + 6;
    const yearsArray = new Array(length).fill(startYear).map((year, index) => year + index);
    return of(yearsArray);
  }

  readonly companyType$: Observable<CompanyTypeDto[]> = this.getDirectory('companyType').pipe(
    map(directories => directories.companyType),
    shareReplay(1),
  );

  readonly charterKind$: Observable<CharterKindDto[]> = this.getDirectory('charterKind').pipe(
    map(directories => directories.charterKind),
    shareReplay(1),
  );

  readonly charterContractStatus$: Observable<CharterContractStatusDto[]> =
    this.getDirectory('charterContractStatus').pipe(
      map(directories => directories.charterContractStatus),
      shareReplay(1),
    );

  readonly charterPeriodStatus$: Observable<CharterPeriodStatusItem[]> = of(CHARTER_PERIOD_STATUS_BADGE).pipe(
    shareReplay(1),
  );

  readonly charterType$: Observable<CharterTypeItem[]> = of(CHARTER_TYPE_BADGE).pipe(
    shareReplay(1),
  );

  readonly periodType$: Observable<PeriodTypeItem[]> = of(CHARTER_PERIOD_TYPE).pipe(
    shareReplay(1),
  );

  readonly charterAdditionalPaymentMethod$: Observable<CharterAdditionalPaymentMethodDto[]> =
    this.getDirectory('charterAdditionalPayment').pipe(
      map(directories => directories.charterAdditionalPayment),
      shareReplay(1),
    );

  readonly supplierRoles$: Observable<SupplierRoleDto[]> =
    this.getDirectory('supplierDictionaryRoles').pipe(
      map(directories => directories.supplierDictionaryRoles),
      shareReplay(1),
    );

  readonly supplierPaymentTerms$: Observable<SupplierPaymentTermDto[]> =
    this.getDirectory('supplierCatalogPaymentTerms').pipe(
      map(directories => directories.supplierCatalogPaymentTerms),
      shareReplay(1),
    );

  readonly charterAdditionalPaymentStatus$: Observable<LabeledItem[]> = of(CHARTER_ADDITIONAL_PAYMENT_STATUSES);

  readonly charterPaymentVatMode$: Observable<LabeledItem[]> = of(CHARTER_VAT_MODES);

  readonly searchRegion$: Observable<SearchRegionItemDto[]> = this.getDirectory('searchRegion')
    .pipe(
      map(directories => directories.searchRegion),
      shareReplay(1),
    );

  readonly supplierGeneralTags$: Observable<SupplierGeneralTag[]> =
    this.getDirectory('supplierGeneralTags')
      .pipe(
        map(directories => directories.supplierGeneralTags),
        shareReplay(1),
      );

  readonly supplierBrands$: Observable<SupplierBrandDto[]> =
    this.getDirectory('supplierDictionaryBrands')
      .pipe(
        map(directories => directories.supplierDictionaryBrands),
        shareReplay(1),
      );

  readonly supplierCatalogRoles$: Observable<SupplierCatalogRoleDto[]> =
    this.getDirectory('supplierDictionaryCatalog')
      .pipe(
        map(directories => directories.supplierDictionaryCatalog),
        shareReplay(1),
      );

  readonly boatEquipmentBrands$: Observable<BrandDto[]> = this.getDirectory('boatEquipmentBrand').pipe(
    map(directories => directories.boatEquipmentBrand),
    shareReplay(1),
  );

  readonly boatTypeSections$: Observable<BoatTypeSectionDto[]> =
    this.getDirectory('boatTypeSection')
      .pipe(
        map(directories => directories.boatTypeSection),
        shareReplay(1),
      );

  readonly timezones$: Observable<TimezoneItemDto[]> =
    this.getDirectory('timezoneGroup')
      .pipe(
        map(directories => directories.timezoneGroup),
        shareReplay(1),
      );

  readonly boatTaskLogSettings$: Observable<TableSettingsDto[]> = this.getDirectory('boatTaskLogSettings').pipe(
    map(directories => directories.boatTaskLogSettings),
    shareReplay(1),
  );

  constructor(private readonly http: TransferHttpService) {
  }

  getCities(countryId: number): Observable<CityDto[]> {
    const params = new HttpParams().append('countryId', `${countryId}`);
    return this.http.get<ResponseDto<CityDto[]>>('api/helper/get-city-list-by-country', {params}).pipe(
      map(response => response.data),
    );
  }

  // TODO: refactor
  getDirectory(group: string): Observable<DirectoriesDto> {
    return this.http.get<ResponseDto<DirectoriesDto>>(`api/helper/get-dictionary?group=${group}`).pipe(
      map(response => response.data),
    );
  }

  subCategories(boatId: number, category: number | number[]): Observable<ResponseDto<SubCategoryDto[]>> {
    const categories = Array.isArray(category) ? buildURLParams(category, true, 'category') : {category};
    const params = httpParamsFromObject({boatId, ...categories});
    return this.http.get('api/expense/get-subcategory-by-category', {params});
  }

  getGeolocationByCoordinates(payload: GeolocationDtoI): Observable<ResponseDto<LocationDtoI>> {
    const params = httpParamsFromObject(payload);
    return this.http.get('api/helper/get-location-by-coordinates', {params});
  }

  getLocationAutoComplete(search: string, includeTypes?: ConditionalStatus.YES, mode: LocationSearchMode | null = null)
    : Observable<ResponseDto<LocationTypeAddress[] | string[]>> {
    const params = httpParamsFromObject({search, includeTypes, mode});
    return this.http.get('api/helper/get-location-autocomplete', {params});
  }

  getCoordinatesByAddress(address: string, timeZone?: ConditionalStatus.YES): Observable<ResponseDto<LocationLatLngDto>> {
    const params = httpParamsFromObject({address, timeZone});
    return this.http.get('api/helper/get-coordinate-by-address', {params});
  }

  getLocationAutocompleteOptions(search: string): Observable<ResponseDto<ExtendedLocationItemDto[]>> {
    const params = httpParamsFromObject({search});
    return this.http.get('api/helper/find-location-autocomplete', {params});
  }

  getPermissionGroupsByRole(payload: UserRoleType): Observable<PermissionsGroupDto[]> {
    const params = httpParamsFromObject({group: 'permissionGroups', payload});
    return this.http.get<ResponseDto<DirectoriesDto>>('api/helper/get-dictionary', {params})
      .pipe(map(res => res.data.permissionGroups));
  }

  getBoatSearchSupplier(payload: BoatSearchSupplierPayload): Observable<string[]> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<string[]>>('api/boat/search-boat-supplier', {params})
      .pipe(map(res => res.data));
  }

  getReleaseInfo(): Observable<ReleaseInfoDto> {
    return this.http.get<ResponseDto<ReleaseInfoDto>>('api/helper/get-release-info')
      .pipe(
        map(res => res.data),
      );
  }

}
