import {SeazoneRadio} from '@controls/seazone-radio';

import {AmountDto} from '../amount';
import {BoatPayload} from '../boat';
import {ContractDto, ContractPeriodDto} from '../contract';
import {BoatCurrentCurrency} from '../directories';
import {Month} from '../general';
import {FileFromServer, UploadedFile} from '../uploaded-file';

export interface AddBudgetDataDto {
  availableYears: AddBudgetYears[];
  positions: BudgetPositions;
}

export interface FoodSalaryDto extends AmountDto {
  subcategoryId: number;
  period: ContractPeriodDto;
  id: number;
  name: string;
}

export interface AddBudgetYears {
  year: number;
  canImport: boolean;
  food: FoodSalaryDto[];
}

export interface BudgetPositions {
  crew: ContractDto[];
  open: ContractDto[];
}

export enum BudgetSkipStatus {
  No = 1,
  Yes
}

export enum BudgetSalaryPosition {
  CREW = 1,
  OPEN = 2,
}

export enum BudgetPeriod {
  DAY = 1,
  MONTH = 2
}

export enum BudgetStatus {
  UNLOCKED = 1,
  LOCKED = 2
}

export enum BudgetViewType {
  Current,
  Comparison,
  RealSpend,
}

export enum BudgetDurationMode {
  Annual = 1,
  Custom = 2,
}

export class BudgetViewItem {
  constructor(
    public readonly id: BudgetViewType,
    public readonly name: string,
  ) {
  }
}

export type BudgetViewMode = 'read' | 'write';

export interface BudgetPayload extends BoatPayload {
  budgetId: number;
}

export interface MonthlyBudgetPayload extends BudgetPayload {
  month: number;
  year: number;
}

export interface BudgetListItem {
  id: number;
  year: number;
  name: string;
  crewHidden: boolean;
}

export class BudgetExcelFilter {
  [key: string]: any;
  constructor(
    public boatId: number,
    public budgetId: number | null,
  ) {
  }
}

export interface BudgetDto {
  id: number | null;
  year: number | null;
  name: string;
  importYear: number | null;
  status: BudgetStatus;
  mode: BudgetDurationMode;
  data: BudgetCategoryDto[];
  months: BudgetTotalDto<BudgetMonthTotal>;
  total: BudgetTotalDto<number>;
  periodFrom: string | null;
  periodTo: string | null;
  label: string | null;
}

export interface BudgetCategoryDto {
  id: number;
  name: string;
  subcategories: BudgetSubCategoryDto[];
  months: BudgetCategoryMonth;
  totals: BudgetCategoryTotalDto;
  crewBudget?: boolean;
}

export interface CategoryMonthBudgetDto {
  current: BudgetValueDto;
  previous: BudgetValueDto;
}

export type BudgetCategoryMonth = [
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto,
  CategoryMonthBudgetDto
];

export interface BudgetSubCategoryDto {
  id: number;
  name: string;
  current: SubcategoryTotal;
  previous: SubcategoryTotal;
  months: SubCategoryMonthBudgetDto[];
  extraSubcategories: BudgetExtraSubcategoryDto[];
  hasComments: boolean;
  hasFiles: boolean;
  crewBudget?: boolean;
}

export interface UpdateBudget {
  budgetId: number;
  budgetDataId?: number | null;
  amount?: number;
  boatSubcategoryId?: number | null;
  budgetCustomSubId?: number | null;
  comment?: string;
  commentFiles?: BudgetCommentFile[];
  removeCommentFiles?: number[];
}

export class BudgetCommentFile {
  constructor(
    public readonly filename: string | null,
    public readonly originFilename: string | null,
  ) {
  }
}

export interface UpdateBudgetDto extends UpdateBudget{
  boatId: number;
}

export interface UpdatedBudgetDto {
  hasComments: boolean;
  hasChanged?: boolean;
  hasFiles?: boolean;
  monthTotal?: number;
  subcategories?: UpdatedBudgetSubcategory[];
  subcategoryTotal?: number;
  generalTotal?: number;
  category?: UpdatedBudgetCategoryDto;
  comment?: BudgetCellHistoryItem;
  extraSubcategoryRowTotal?: number;
  extraSubcategoryColumnTotal?: number;
}

export type BudgetCellValueType = 'subCategory' | 'extraSubCategory';

export interface UpdatedBudgetCategoryDto {
  monthCurrentBudget: number;
  totalsCurrentBudget: number;
}

export interface UpdatedBudgetSubcategory {
  id: number;
  percent: number;
}

export interface UpdatedBudgetCell {
  value?: number;
  hasComments?: boolean;
  hasChanged?: boolean;
  hasFiles?: boolean;
}

export interface EditableCell {
  id: number;
  value: string;
  previosValue: string | number | null;
}

export interface BudgetExtraSubcategoryDto {
  id: number;
  months: BudgetExtraSubCategoryMonthDto[];
  name: string;
  total: number;
  hasComments: boolean;
  hasFiles: boolean;
}

export interface BudgetExtraSubCategoryMonthDto {
  id: number;
  current: BudgetValueDto;
  hasChanged: boolean;
  hasComments: boolean;
  hasFiles: boolean;
  month: number;
  year: number;
}

export interface MonthlyBudgetDto {
  actuallyTotal: number;
  dataReport: MonthlyBudgetCategoryDto[];
  differenceTotal: number;
  monthlyTotal: number;
  ytdActuallyTotal: number;
  ytdDifferenceTotal: number;
  ytdMonthlyTotal: number;
}

export interface MonthlyBudgetCategoryDto {
  id: number;
  name: string;
  subcategories: MonthlyBudgetSubCategoryDto[];
  totals: MonthlyBudgetTotals;
}

export interface MonthlyBudgetTotals {
  actually: number;
  difference: number;
  monthly: number;
  ytdActually: number;
  ytdDifference: number;
  ytdMonthly: number;
}

export interface MonthlyBudgetSubCategoryDto {
  actually: number | null;
  difference: number | null;
  id: number;
  monthly: number | null;
  name: string;
  percent: number | null;
  ytdActually: number | null;
  ytdDifference: number | null;
  ytdMonthly: number | null;
}

export interface SubcategoryTotal extends BudgetTotalItem<number> {
  budgetPercent: number;
  spendPercent: number;
  budgetTotal: number;
  spendTotal: number;
}

export interface SubCategoryMonthBudgetDto {
  id: number;
  month: number;
  current: BudgetValueDto;
  previous: BudgetValueDto;
  hasChanged: boolean;
  hasComments: boolean;
  hasFiles: boolean;
  year: number;
}

export interface BudgetValueDto {
  budget: number | null;
  spend: number | null;
}

export type BudgetMonthTotal =
  [number, number, number, number, number, number, number, number, number, number, number, number];

export type BudgetComparisionTotal = [
  number, number, number, number, number, number, number, number, number, number, number, number,
  number, number, number, number, number, number, number, number, number, number, number, number,
];

export interface BudgetTotalDto<T> {
  current: BudgetTotalItem<T>;
  previous: BudgetTotalItem<T>;
}

export interface BudgetCategoryTotalDto {
  current: BudgetValueDto;
  previous: BudgetValueDto;
}

export interface BudgetTotalItem<T> {
  budgetTotal: T;
  spendTotal: T;
}

export interface BudgetGeneralTableModal {
  budget: BudgetDto;
  boatName: string;
  months: Month[];
  editable: boolean;
  showComments: boolean;
}

export interface BudgetChartModal {
  months: BudgetTotalDto<BudgetMonthTotal>;
  year: number;
  type: BudgetViewType;
}

export interface BudgetMonthlyPayload {
  monthId: number;
  year: number;
}

export interface BudgetMonthlyTableModal {
  monthlyBudget: MonthlyBudgetDto;
  selectedMonth: Month;
  currency: BoatCurrentCurrency;
  months: Month[];
  budgetId: number;
}

export interface BudgetYearComparisonTableModal {
  budget: BudgetDto;
  boatName: string;
  months: Month[];
  currency: BoatCurrentCurrency;
}

export interface BudgetSpendingComparisonTableModal {
  budget: BudgetDto;
  boatName: string;
  months: Month[];
  currency: BoatCurrentCurrency;
}

export enum BudgetCommentType {
  SubCategory = 1,
  Cell = 2,
}

export class BudgetCommentItem {
  constructor(
    public readonly id: BudgetCommentType,
    public readonly name: string | null = null,
  ) {
  }
}

export class BudgetCommentCellItem {
  constructor(
    public readonly id: BudgetCommentType,
    public readonly name: string,
  ) {
  }
}

export class AddBudgetCommentDirectories {
  constructor(
    public readonly cells: BudgetCommentCellItem[],
  ) {
  }
}

export interface AddBudgetCommentModalData {
  period: Month[];
  cell: BudgetSubCategoryDto | BudgetExtraSubcategoryDto;
}

export interface AddCommentItem {
  comment: string;
  budgetDataId?: number;
  subcategoryId?: number;
  commentFiles?: UploadedFile[];
  removeCommentFiles?: number[];
}

export interface UpdateCommentItem {
  comment: string;
  id: number;
  commentFiles?: (UploadedFile | FileFromServer)[];
  removeCommentFiles?: number[];
}

export interface UpdateTableCellItem {
  comment?: string;
  amount: number;
}

export interface BudgetCellHistoryModal {
  budget: BudgetDto;
  budgetPeriod: Month[];
  boatCategoryId: number;
  boatSubcategoryId: number;
  budgetCustomSubId: number | null;
  name: string;
  type: BudgetCellValueType;
  monthIndex: number | null;
  budgetDataId: number | null;
  budgetDataValue: number | null;
}

export interface BudgetCellHistoryPayload extends BudgetPayload {
  boatSubcategoryId: number | null;
  budgetDataId: number | null;
  budgetCustomSubId: number | null;
}

export interface BudgetCellHistoryItem {
  id: number;
  amount: number | null;
  comment: string | null;
  date: string;
  canChange: boolean;
  user: BudgetCellHistoryUser;
  files: UploadedFile[];
}

export interface BudgetCellHistoryUser {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
}

export interface DeleteCommentPayload extends BudgetPayload {
  payload: number;
}

export interface EditCommentPayload extends BudgetPayload {
  payload: number;
  comment: string;
  commentFiles?: BudgetCommentFile[] | null;
  removeCommentFiles?: number[];
}

export enum BudgetCellCommentMode {
  View,
  Add,
  Edit,
  Delete,
}

export interface DeleteCommentDto {
  hasComments: boolean;
  hasFiles: boolean;
}

export interface UpdateSubcatCommentDto {
  hasFiles: boolean;
}

export interface BudgetStickyTableHeader {
  monthsTotals: BudgetMonthTotal;
  total: number;
}

export class BudgetImportDataDto {
  constructor(
    public readonly budget: BudgetImportDataItemDto[],
    public readonly expenses: number[],
  ) {
  }
}

export class BudgetImportDataItemDto {
  constructor(
    public readonly id: number,
    public readonly year: number,
    public readonly name: string,
  ) {
  }
}

export class BudgetImportDataDirectories {
  constructor(
    public readonly modes: SeazoneRadio[],
    public readonly years: BudgetImportDataDto,
  ) {
  }
}

export enum BudgetImportType {
  Budget = 1,
  Expenses
}

export interface BudgetImportForm {
  mode: BudgetImportType;
  applyYear?: number;
  applyBudgetId?: number;
}

export interface BoatBudgetImportPayload extends BudgetImportForm, BoatPayload {
  budgetId: number;
}

export interface AddBudgetExtraSubCategoryPayload extends BudgetPayload {
  parentSubcategoryId: number;
  name: string | null;
}

export interface UpdateBudgetExtraSubCategoryPayload extends BudgetPayload {
  subcategoryId: number;
  name: string;
}

export interface DeleteBudgetExtraSubCategoryPayload extends BudgetPayload {
  subcategoryId: number;
}

export interface BudgetCellHistoryEvent {
  budgetDataId: number;
  budgetDataValue: number;
  monthIndex: number;
}

export interface BudgetCellUpdateValueEvent {
  monthIndex: number;
  cellData: EditableCell;
}
