import {BudgetPeriod} from '@models/budget';
import {ContractPeriod, ContractPeriodList} from '@models/crew';

export const contractCrewPeriods: ContractPeriod[] = [
  new ContractPeriod(ContractPeriodList.DAY, 'general.day'),
  new ContractPeriod(ContractPeriodList.MONTH, 'general.month'),
];

export const budgetPeriods: ContractPeriod[] = [
  new ContractPeriod(BudgetPeriod.DAY, 'general.day'),
  new ContractPeriod(BudgetPeriod.MONTH, 'general.month'),
];
