import {ConditionalStatus} from '@models/general';
import type {InvoiceListItem} from '@models/invoice';

/**
 * Static list for filter data by invoice.
 * For displaying all data value should be null
 */
export const INVOICE_FILTER_LIST = new Map<ConditionalStatus | null, InvoiceListItem>([
  [null, {
    title: 'general.conditions.all',
    value: null,
  }],
  [ConditionalStatus.YES, {
    title: 'general.conditions.yes',
    value: ConditionalStatus.YES,
  }],
  [ConditionalStatus.NO, {
    title: 'general.conditions.no',
    value: ConditionalStatus.NO,
  }],
]);
