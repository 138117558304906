import {JobStartDateDto, JobStartDateType} from '@models/directories';

const translation = (type: string): string => `search.startDate.${type}`;

export const JOB_START_DATE_LIST: JobStartDateDto[] = [
  new JobStartDateDto(JobStartDateType.Asap, translation('asap')),
  new JobStartDateDto(JobStartDateType.Week, translation('week')),
  new JobStartDateDto(JobStartDateType.Month, translation('month')),
  new JobStartDateDto(JobStartDateType.Date, translation('date')),
];
