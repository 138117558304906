import {ExpenseStatus, ExpenseStatusItem, ExpenseType, ExpenseTypeItem} from '@models/expense';
import {FinancialDataSortItem} from '@models/financial-data';

const expenseTypeKey = 'expenseTypes';
const expenseStatusKey = 'expense.status';
const iconPath = 'assets/img';

export const EXPENSE_TYPE_LIST: ExpenseTypeItem[] = [
  {
    id: null,
    name: `${expenseTypeKey}.all`,
  },
  {
    id: ExpenseType.NO_RECEIPT,
    name: `${expenseTypeKey}.noReceipt`,
  },
  {
    id: ExpenseType.RECEIPT,
    name: `${expenseTypeKey}.receipt`,
  },
];

export const EXPENSE_STATUSES_LIST: ExpenseStatusItem[] = [
  {id: ExpenseStatus.CORRECT, name: `${expenseStatusKey}.correct`, icon: `${iconPath}/check-icon.svg`},
  {id: ExpenseStatus.CHECK, name: `${expenseStatusKey}.check`, icon: `${iconPath}/check.svg`},
  {id: ExpenseStatus.INCORRECT, name: `${expenseStatusKey}.incorrect`, icon: `${iconPath}/incorrect.svg`},
  {id: ExpenseStatus.EMPTY, name: `${expenseStatusKey}.empty`, icon: null},
];

export const DEFAULT_FINANCIAL_DATA_SORT: FinancialDataSortItem = {
  id: 'create',
  name: 'financialData.sort.create',
};

export const FINANCIAL_DATA_SORT: FinancialDataSortItem[] = [
  {
    id: 'date',
    name: 'financialData.sort.receipt',
  },
  {
    id: 'amount',
    name: 'financialData.sort.amount',
  },
  DEFAULT_FINANCIAL_DATA_SORT,
];
