import {LabeledItem} from '@models/general';
import {UpdateCrewStatus} from '@models/job-offers';

export const UNPUBLISH_OFFER_ACTIONS: LabeledItem[] = [
  {
    id: UpdateCrewStatus.Update,
    name: 'search.jobOffer.modal.updateAllCrew',
  },
  {
    id: UpdateCrewStatus.NotUpdate,
    name: 'search.jobOffer.modal.dontUpdateAllCrew',
  },
];
