import {CrewGroupType} from '@models/crew/crew.model';
import {LabeledItem} from '@models/general';

export const USER_GROUPS: LabeledItem[] = [
  {
    id: CrewGroupType.Crew,
    name: 'crew.groupCrew',
  },
  {
    id: CrewGroupType.Additional,
    name: 'crew.groupAdditional',
  },
];
