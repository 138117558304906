import {CharterPaymentStatus, CharterPaymentVatMode} from '@models/charters/charter.model';
import {LabeledItem} from '@models/general/labeled-item';

export const CHARTER_ADDITIONAL_PAYMENT_STATUSES: LabeledItem<CharterPaymentStatus>[] = [
  {id: CharterPaymentStatus.NotRelevant, name: 'charter.payment.notRelevant'},
  {id: CharterPaymentStatus.Included, name: 'charter.payment.included'},
  {id: CharterPaymentStatus.FixPrice, name: 'charter.payment.fixPrice'},
];
export const CHARTER_VAT_MODES: LabeledItem<CharterPaymentVatMode>[] = [
  {id: CharterPaymentVatMode.GrossIncomeAndDelivery, name: 'charter.payment.grossAndDelivery'},
  {id: CharterPaymentVatMode.GrossIncome, name: 'charter.payment.grossOnly'},
  {id: CharterPaymentVatMode.All, name: 'charter.payment.vatAll'},
];
